import React from 'react';
import WDHResourceCenter, { FilterViewConfig, ListItemViewConfig } from '@demant/resource-center';
import ListView from '@demant/resource-center/lib/js/views/list-view';
import FilterCollectionElementView from '@demant/resource-center/lib/js/views/filters/filter-collection-element-view';
FilterViewConfig.expandFiltersOnLoad = true;
var resourceCenter = new WDHResourceCenter('.resource-center:not(.grid-layout)');
resourceCenter.filterCallback = function (filter, element) {
  if (element.name === 'Dansk (DK)') {
    resourceCenter.updateFilter({
      id: element.id,
      selected: true
    });
    element.selected = true;
  }
  return element;
};
resourceCenter.run();